import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";
import './index.css';
const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY as string,
  client_id: process.env.REACT_APP_CLIENT_ID as string,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI as string,
  // ...
};

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <AuthProvider {...oidcConfig}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
